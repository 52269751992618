@import "framework";
@import "theme";
@import "framework";
@import "theme";

//

.logo {
  padding: auto;
}
.pull-up-phonebook {
  margin-top: -13%;
}
// the table on the pricing page is a little wide. This sets wide for the table and p with class .pricing
$pricing-width: 60rem;

td {
  padding-left: 10px;
  text-align: center;
}
select#redirects {
  height: 8rem;
}

.logo-image img {
  margin-top: 1rem;
  margin-left: 5px;
}
.card .card-thumbnail img {
  object-fit: cover !important;
}

.lc-icon img {
  height: 20px;
  display: inline-block;
  padding: 0%;
  margin: 5px;
}

@media all and (min-width: 500px) and (max-width: 1024px) {
  /* put your css styles in here */
  // .container {
  //   padding-top: 10px;
  //   padding-left: 0px;
  //   padding-right: 0px;
}

@media all and (max-width: 500px) {
  .hero-text {
    padding-top: 4rem;
    p {
      font-size: 20px !important;
    }
  }
  h1 {
    font-size: 30px !important;
  }
  h2 {
    font-size: 20px !important;
  }
  .logo-image img {
    margin-top: -2rem !important;
  }
}

h2.testimonial {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

p.testimonial {
  border-left: 5rem;
  padding: 1rem;
  border-radius: 10px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: lightgray;
  // padding-right: 10rem;
}

a.dark-background {
  font-weight: bolder;
  color: white;
}

div.pricing-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

div.pricing-child {
  margin: auto;
  width: auto;
  height: auto;
}

table.pricing {
  border-radius: 0px;

  table-layout: fixed;
  border-collapse: collapse;

  width: $pricing-width;

  a {
    text-decoration: none;
  }

  td {
    border: 1px solid;
  }
  th {
    text-align: center;
    border: 1px solid;
  }
  .fa-check-square {
    color: green;
  }
  .fa-rectangle-xmark {
    color: red;
  }
}

p.pricing {
  width: $pricing-width;
}

// https://stackoverflow.com/questions/2011142/how-to-change-the-style-of-the-title-attribute-inside-an-anchor-tag
// Can't change mouseover title, but can do it a harder way

.form-group {
  label {
    // font-size: calc(1.275rem + 0.3vw) !important;
  }
  legend {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
}
.form-group-radio {
  legend {
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 16px;
  }
}
