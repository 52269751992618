:root {
  --font-family-base: 'Source Sans 3', sans-serif;
  --font-family-heading: Lato, sans-serif;
  --font-family-monospace: 'Source Code Pro', monospace;
  --font-family-logo: Lato, sans-serif;

  --color-primary-bg: #2b3af7;
  --color-primary-bg-2: #424ff8;
  --color-primary-bg-3: #702bf7;
  --color-primary-text: #fdfcf9;
  --color-base-bg: #ffffff;
  --color-base-bg-2: #f8f9ff;
  --color-base-bg-3: #eef2f9;
  --color-base-text: #202020;
  --color-base-text-2: #4b5563;
  --color-header-text:  #111827;
  
  --logo-text-font-size: 28px;
  --logo-text-font-weight: 500;

  --menu-font-family: 'Source Sans 3', sans-serif;
  --menu-font-size: ;
  --menu-text-transform: "Capitalize";
  --menu-font-weight: 400;

  --header-height: 60px;
  --section-padding: 30px; // jp was 80px
  --card-border-radius: 5px;
  --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
  --color-primary-bg: #4486f2;
  --color-primary-bg-2: #2f74e5;
  --color-primary-bg-3: #8215cb;
  --color-primary-text: #fdfcf9;
  --color-base-bg: #0f0f10;
  --color-base-bg-2: #1a191c;
  --color-base-bg-3: #222124;
  --color-base-text: #F4F4F5;
  --color-base-text-2: #D1D5DB;
  --color-logo-text: #2b3af7;
  --color-header-text: #F4F4F5;
}

@import 'style';
